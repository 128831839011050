import React, { useState } from 'react';
import VideoModal from '../ModalVideo'
import {Link} from 'react-router-dom'
import abimg1 from '../../images/about/web_655_logo_n-logo.png'
import abimg2 from '../../images/about/img-1.png'
import Parser from 'html-react-parser';

import './style.css'
import './../../i18n';
// import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
const AboutSection = () => {
    const { t,i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState('en');
   
    const changeLanguage = (event) => {
      setSelectedLang(event.target.value);
      i18n.changeLanguage(event.target.value);
      localStorage.setItem('lang',event.target.value);
    }
    return(
        <div id="about-section" className="about-style-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-12 col-m">
                        <div className="about-img">
                            <img src={abimg1} alt=""/>
                            {/* <VideoModal/> */}
                        </div>
                    </div>
                    <div className="col-lg-6 col-12" >
                        <div className="about-content">
                            <div className="section-title">
                                <h2 >{t('about-label')}</h2>
                            </div>
                            <p className="justify-text">{Parser(t('about-mdscresoft-one-label'))}</p>
                            <p className="justify-text">
                            <ul>
                                <li style={{marginLeft:"10px"}}><i className="fa fa-check"></i>{t('about-mdscresoft-one-tel-label')}</li>
                                <li style={{marginLeft:"10px"}}><i className="fa fa-check"></i>{t('about-mdscresoft-one-engineer-label')}</li>
                                <li style={{marginLeft:"10px"}}><i className="fa fa-check"></i>{t('about-mdscresoft-one-electro-label')}</li>
                            </ul>
                            {Parser(t('about-mdscresoft-two-label'))}<br/>
                            </p> 
                            
                        </div>
                    </div>
                    <div className="col-lg-12 col-12">
                        <p className="justify-text">
                            {Parser(t('about-mdscresoft-three-label'))}
                        </p>
                    </div>
                    {/* <div className="btns">
                        <div className="btn-style"><Link to="/">{t('more-about-label')}</Link></div>
                    </div> */}
                    <div className="signature">
                        <img src={abimg2} alt=""/>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default AboutSection;
