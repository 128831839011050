import React,{ useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import Parser from 'html-react-parser';
import MobileMenu from '../../components/MobileMenu';
import logo from '../../images/logo/web_655_logo_n-logo.png';
import { Button } from 'reactstrap';
import './style.css'
import i18next from 'i18next';
import i18n from './../../i18n';
// import i18n from './../../i18n';
import { useTranslation } from 'react-i18next';
const Header = () => {
    const lang=localStorage.getItem('lang');
    const { i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState(lang=="fr"?i18n.t('french-label'):i18n.t('english-label'));
    // console.log('default language stored='+localStorage.getItem('lang'));
    const changeLanguage = (event) => {
      i18n.changeLanguage(event.target.value);
      localStorage.setItem('lang',event.target.value);
      setSelectedLang(localStorage.getItem('lang'));

      console.log('language='+selectedLang);
      console.log('language stored='+localStorage.getItem('lang'));
      console.log("url=>"+window.location.href);
      window.location.reload(false);
    }
 
    var day = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    var months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    var daysFrench = ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'];
    var monthsFrench = ['Janvier','Février','Mars','Avril','May','Juin','Juillet','Aout','Septembre','Octobre','Novembre','Décembre'];
    const SubmitHandler = ( e ) => {
       e.preventDefault()
    }
    const [dateTime, setDateTime] = useState(new Date());
    useEffect(() => {
        const id = setInterval(() => setDateTime(new Date()), 1000);
        return () => {
            clearInterval(id);
        }
    }, []);
    var day = day[ dateTime.getDay() ];
    var month = months[ dateTime.getMonth() ];
    var options = {  weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
    var prnDt =localStorage.getItem('lang')=="en"?new Date().toLocaleTimeString('en-us', options):new Date().toLocaleTimeString('fr-FR', options);
    return(
            <header>
                <div id="header-section" className="header-top-1" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-sm-12 col-12 col-lg-10 list-center">
                                <ul className="d-flex account_login-area">
                                    {/* <li><i className="fa fa-clock-o" aria-hidden="true"></i>{`${prnDt}`}</li> */}
                                    <li style={{fontSize:"15px"}} className="fade-in-text"><a href="/#map-section"><i className="fa fa-map-marker"></i></a>{Parser(i18n.t('mdscresoft-address'))}</li>
                                    <li className="fade-in-text"><i className="fa fa-phone"></i>{Parser(i18n.t('call-label'))} : <a href="tel:222 45 25 93 40">+222 45 25 93 40</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12">
                                <div className="btn-style">
                                    <select onChange={changeLanguage} className="select-language">
                                        <option selected value={selectedLang}>{selectedLang}</option>
                                        <option value="en">{i18n.t('english-label')}</option>
                                        <option value="fr">{i18n.t('french-label')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-style-1">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-lg-3 col-md-10 col-sm-10 col-8 col-t"style={{width:"200px"}}> */}
                            <div style={{width:"120px"}}>

                                <div className="logo-society">
                                    <a href="/"><img src={logo} alt=""/></a>
                                </div>
                            </div>
                            <div className="col-lg-10 d-none d-lg-block col-m">
                                <div className="main-menu">
                                    <nav className="nav_mobile_menu">
                                        <ul>
                                            <li className="active"><a href="/">{i18n.t('home-label')}</a>
                                                {/* <ul className="submenu">
                                                    <li className="active"><Link to="/">Home One</Link></li>
                                                    <li><Link to="/home2">Home Two</Link></li>
                                                    <li><Link to="/home3">Home Three</Link></li>
                                                </ul> */}
                                            </li>
                                            <li><a href="/#about-section">{i18n.t('about-label')}</a></li>
                                            <li><Link className="communication" to="/communication">{i18n.t('communication-label')}</Link></li>
                                            <li><a href="/#practice-section">{i18n.t('practice-label')}</a>
                                                <ul className="submenu">
                                                    <li><a href="/#practice-section">{i18n.t('practice-label')}</a></li>
                                                    <li><Link to="/telecom">{i18n.t('practice-detail-label')}</Link></li>
                                                </ul>
                                            </li>
                                            <li><a href="/#partners-section">{i18n.t('partner-label')}</a>
                                                {/* <ul className="submenu">
                                                    <li><Link to="/case-stadies">Cases</Link></li>
                                                    <li><Link to="/case-stadies-details">Case single</Link></li>
                                                </ul> */}
                                            </li>
                                            <li><a href="/#clients-section">{i18n.t('client-label')}</a>
                                                {/* <ul className="submenu">
                                                    <li><Link to="/blog">Blog Grid</Link></li>
                                                    <li><Link to="/blog-list">Blog with sidebar</Link></li>
                                                    <li><Link to="/blog-fullwidth">Blog full width</Link></li>
                                                    <li><Link to="/blog-single">Blog single sidebar</Link></li>
                                                    <li><Link to="/blog-single-fullwidth">Blog single fullwidth</Link></li>
                                                </ul> */}
                                            </li>
                                            {/* <li><Link to="/">Pages</Link>
                                                <ul className="submenu">
                                                    <li><Link to="/Attorneys">Attorneys</Link></li>
                                                    <li><Link to="/Attorneys-single">Attorney Single</Link></li>
                                                    <li><Link to="/faq">FAQ</Link></li>
                                                </ul>
                                            </li> */}
                                            <li><a href="/#contact-section">{i18n.t('contact-label')}</a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            {/* <div className="col-lg-1 col-md-1 col-sm-1 col-1 search">
                                <ul>
                                    <li><Link to="/"><i className="fa fa-search"></i></Link>
                                        <ul>
                                            <li>
                                                <form onSubmit={SubmitHandler}>
                                                    <input type="text" placeholder="search here.."/>
                                                    <button type="btn"><i className="fa fa-search"></i></button>
                                                </form>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div> */}
                            <div className="col-md-1 col-sm-1 col-1" style={{right:"10%",position:"absolute"}}>
                                <MobileMenu/>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
     )
        
}

export default Header;
