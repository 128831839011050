import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import './index.css'
import abimg1 from '../../images/icon/21.png'
import i18next from 'i18next';
import i18n from './../../i18n';

class Ticket extends Component {

    handleClick = e => {
        window.open("https://support.mdscresoft-gestion.com/index.php?page=admin&subpage=list&table=tcompany&action=disp_list");
      }
    render() {



        return (
            <div className="contain">
                    {/* <img  className="image" src={abimg1} alt=""/> */}
                    <div className="image img-class"></div>
                    <span className="caption" onClick={this.handleClick}>
                        {i18n.t('ticket-create-label')}
                        </span>
            </div>
        );
    }
}

export default Ticket;