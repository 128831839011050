import i18next from 'i18next';
import React from 'react';
import {Link} from 'react-router-dom'
import logo from '../../images/logo/thumbnail_logo_orange.png'
import './style.css'
import i18n from './../../i18n';
const FooterSection = () => {

    return(
        <div className="footer-area-orange">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footer-logo">
                                <Link to="/"><img src={logo} alt=""/></Link>
                            </div>
                            <p>{i18n.t('footer-goal-label')} &#128525;</p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footer-link">
                                <h3>{i18n.t('footer-quick-link-label')}</h3>
                                <ul>
                                    <li><a href="/">{i18n.t('home-label')}</a></li>
                                    <li><Link className="communication" to="/communication">{i18n.t('communication-label')}</Link></li>
                                    <li><a href="/#practice-section">{i18n.t('practice-label')}</a></li>
                                    <li><a href="/#partners-section">{i18n.t('partner-label')}</a></li>
                                    <li><a href="/#clients-section">{i18n.t('client-label')}</a></li>
                                    <li><a href="/#contact-section">{i18n.t('contact-label')}</a></li>
                                </ul>
                            </div>
                        </div>
                        <div  id="footer-section"  className="col-lg-5 col-md-6 col-sm-6">
                            <div className="footer-practice bd-0">
                                <h3>{i18n.t('practice-label')}</h3>
                                <ul>
                                    <li><Link to="/telecom">{i18n.t('practice-telecom-pole-label')}</Link></li>
                                    <li><Link to="/computer-science">{i18n.t('practice-computer-science-label')}</Link></li>
                                    <li><Link to="/software">{i18n.t('practice-software-label')}</Link></li>
                                    <li><Link to="/training">{i18n.t('practice-certification-label')}</Link></li>
                                    <li><Link to="/solutions">{i18n.t('practice-solution-label')}</Link></li>
                                    <li><Link to="/support">{i18n.t('practice-support-label')}</Link></li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="widget newsletter-widget">
                                <div className="widget-title">
                                    <h3>Newsletter</h3>
                                </div>
                                <p>Samsa was a travelling salesman and above it there hung a picture that he had recently</p>
                                <form>
                                    <div className="input-1">
                                        <input type="email" className="form-control" placeholder="Email Address *" required/>
                                    </div>
                                    <div className="submit clearfix">
                                        <button type="submit"><i className="fa fa-envelope-o" aria-hidden="true"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="footer-bottom" style={{backgroundColor:"#1f2d3d"}}>
                <div className="container">
                    <div className="footer-bottom-content">
                        <div className="row">
                            <div className="col-lg-7 col-md-8 col-sm-9 col-12">
                                {/* <span>{i18n.t('footer-policy-label')} | © 2021 <Link to="/">MDSCRESOFT-Group</Link>. {i18n.t('footer-rights-label')}</span> */}
                                <span>{i18n.t('footer-policy-label')} | © 2021 MDSCRESOFT-Group. {i18n.t('footer-rights-label')}</span>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-3 col-12">
                                <ul className="d-flex">
                                    {/* <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li> */}
                                    <li><a href="https://twitter.com/mdscresoftgroup" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="https://mr.linkedin.com/in/mdscresoft-contact-243058111" target="_blank"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                    <li><a href="https://wa.me/22245259340" target="_blank"><i className="fa fa-whatsapp" aria-hidden="true"></i></a></li>
                                    <li><a href="https://t.me/22249541982" target="_blank"><i className="fa fa-telegram" aria-hidden="true"></i></a></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default FooterSection;
