import React, { Component } from 'react'
import { Collapse, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom'

import PerfectScrollbar from 'react-perfect-scrollbar'
import i18next from 'i18next';
import i18n from './../../i18n';
import './style.css';

import 'react-perfect-scrollbar/dist/css/styles.css';
const menus = [
    {
        id: 1,
        title: 'home-label',
        link: '/',
        // submenu: [
        //     {
        //         id: 11,
        //         title: 'Home One',
        //         link: '/home'
        //     },
        //     {
        //         id: 12,
        //         title: 'Home One 2',
        //         link: '/home2'
        //     },
        //     {
        //         id: 13,
        //         title: 'Home One 3',
        //         link: '/home3'
        //     },
        // ]
    },
    {
        id: 2,
        title:'about-label',
        link: '/#about-section'
    },
    {
        id: 3,
        title:'communication-label',
        link: '/communication'
    },
    {
        id: 4,
        title:'practice-label' ,
        link:'/#practice-section',
        submenu: [
            {
                id: 41,
                title: 'practice-label',
                link: '/#practice-section'
            },
            {
                id: 42,
                title:'practice-detail-label',
                link: '/#footer-section'
            },
        ]
    },
    {
        id: 5,
        title: 'partner-label',
        link:'/#partners-section'
    },
    {
        id: 6,
        title: 'client-label',
        link: '/#clients-section'
    },
    {
        id: 7,
        title:'contact-label',
        link: '/#contact-section',
    }
    
]


class MobileMenu extends Component {
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside(event) {
        console.log("outside click!");
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
                isMenuShow: false
            })
        }
    }

    state = {
        isMenuShow: false,
        isOpen: 0,
    }

    menuHandler = () => {
        this.setState({
            isMenuShow: !this.state.isMenuShow
        })
    }

    setIsOpen = id => () => {
        this.setState({
            isOpen: id === this.state.isOpen ? 0 : id
        })
    }

    render() {

        const { isMenuShow, isOpen } = this.state;

        return (
            <div>
                <PerfectScrollbar >
                    <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}ref={this.wrapperRef}>
                        {/* <div className="clox" onClick={this.menuHandler}>Close Me</div> */}

                        <ul className="responsivemenu">
                            {menus.map(item => {
                                return (
                                    <li key={item.id}>
                                        {item.submenu ? <p onClick={this.setIsOpen(item.id)}>
                                            {i18n.t(item.title)}
                                            {item.submenu ? <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}
                                        </p> : <a href={item.link}>{i18n.t(item.title)}</a>}
                                        {item.submenu ?
                                        <Collapse isOpen={item.id === isOpen}>
                                            <Card>
                                                <CardBody>
                                                    <ul>
                                                        {item.submenu.map(submenu => (
                                                            // submenu.title=="/telecom"?
                                                            // <li key={submenu.id}><Link className="active" to="/telecom">{submenu.title}</Link></li>:
                                                            <li key={submenu.id}><a className="active" href={submenu.link}>{i18n.t(submenu.title)}</a></li>
                                                        ))}
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </Collapse>
                                        : ''}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    </PerfectScrollbar>
                <div className="showmenu" onClick={this.menuHandler} ><i className="fa fa-bars" aria-hidden="true"></i></div>
            </div>
        )
    }
}

export default MobileMenu;