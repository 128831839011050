import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/breadcumb'

import PracticDetails from '../../components/practicedetails'
import FooterSection from '../../components/Footer'
import i18next from 'i18next';
import i18n from './../../i18n';


const PracticeDetails = () => {
    return(
       <div>
           <Navbar/>
           <Breadcumb bdtitle={i18n.t('practice-label')} bdsub={i18n.t('practice-detail-label')}/>
           <PracticDetails/>
           <FooterSection/>
       </div>
    )
}

export default PracticeDetails;