import React, { Component } from 'react';
import Modal from "./Modal";
import validator from 'validator' ;
import Spinner from 'react-bootstrap/Spinner'
import axios from 'axios';
import "./styles.css";
import i18next from 'i18next';
import i18n from './../../i18n';
import { API_BASE_URL } from './../../config'
const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

class ContactForm2 extends Component {

    constructor(props) {
        super(props);
        this.state= {
            name : null,
            phone : null,
            email : null,
            message: null,
            subject:null,
            code:null,
            isOpen: false,
            success:false,
            hidden: true,
            loading:false,
            dataErrors:[],
            externalError:"",
            errors: {
                name:'',
                phone:'',
                email: '',
                message: '',
                subject:'',
                code:''
            }
        };
        this.toggleShow = this.toggleShow.bind(this);
      }


      modalOpen() {
        this.setState({ modal: true });
    }

    modalClose() {
        this.setState({
        modal: false
        });
    }
    toggleShow() {
        this.setState({ hidden: !this.state.hidden });
    }
    
    handleTextChange = (event) => {
        event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case 'name': 
        errors.name = 
          value.length < 1
            ? i18n.t('contact-field-required-error-label')
            : '';
        break;
    case 'subject': 
        errors.subject = 
          value.length < 1
            ? i18n.t('contact-field-required-error-label')
            : '';
        break;
    case 'phone': 
        errors.phone = 
        value.length < 1
            ? i18n.t('contact-field-required-error-label')
            : (!validator.isMobilePhone(value))?i18n.t('contact-invalid-phone-error-label'):'';
        break;
    case 'code': 
        errors.code = 
          value.length < 1
          ? i18n.t('contact-field-required-error-label')
          : (value.startsWith("+(")&&value.endsWith(")")) ? '' :i18n.t('contact-invalid-code-error-label');
        break;
    case 'email': 
        errors.email = 
        value.length < 1
            ? i18n.t('contact-field-required-error-label')
            :
            (validEmailRegex.test(value))
            ? ''
            : i18n.t('contact-invalid-email-error-label');
        break;
      case 'message': 
        errors.message = 
          value.length < 6
            ? i18n.t('contact-min-message-error-label')
            : '';
        break;
      default:
        break;
    }

    this.setState({errors, [name]: value});
  }
    handleOnSubmit = (event) => {
        event.preventDefault();
        /* if(validateForm(this.state.errors)) { */
            const user = {
                name: this.state.name,
                phone : this.state.phone,
                message:this.state.message,
                email : this.state.email,
                subject : this.state.subject,
                code:this.state.code
              };
              this.setState({ loading: true });
          
              axios.post(API_BASE_URL+`/contact`,  user )
                .then(res => {
                  console.log(res);
                  console.log(res.data);
                  this.setState({ loading: false });
                  if(res.data.code=="success"){
                    this.setState({
                        success: true
                        });
                    this.modalOpen();
                  
                    console.log("modal status=>"+this.state.modal);
                    this.form.reset();
                  }
                })
                .catch((error) => {
                    console.log("data :"+JSON.stringify(error));
                    this.setState({
                        success: false
                        });
                    this.setState({ loading: false });
                    if(error.response.data.code=="fails_validation"){
                        this.setState({ dataErrors: error.response.data.error_message });
                        this.modalOpen();
                        console.log("modal status=>"+JSON.stringify(this.state.dataErrors));
                      }
                      if(error.response.data.code=="fails_extern"){
                        this.setState({ externalError: error.response.data.error_message });
                        this.modalOpen();
                        console.log("modal status=>"+JSON.stringify(this.state.externalError));
                      }
                    
                  });
            
          /* else{
            alert('Invalid Form')
          }
         */
    };

    render() {
        const {errors} = this.state;
        const {dataErrors} = this.state;
        const {externalError} = this.state;
        return (
            <form ref={form => this.form = form} onSubmit={this.handleOnSubmit}>
                <div className="contact-form form-style row">
                    <div className="col-12 col-lg-6">
                    <input type="text" name="name" 
                        id="name" placeholder={i18n.t('contact-name-label')} 
                        required
                        onChange={this.handleTextChange} />
                        {/* <i className="fas fa-user"></i> */}
                        {errors.name.length > 0 && 
                        <span style={{color:"#dc3545"}}>{errors.name}</span>}
                    </div>
                    <div className="col-12  col-lg-6">
                    <input type="email"
                        name="email" 
                        id="email" 
                        placeholder={i18n.t('contact-email-label')} 
                        required
                        onChange={this.handleTextChange} />
                    {/* <i className="fas fa-envelope"></i> */}
                    {errors.email.length > 0 && 
                        <span style={{color:"#dc3545"}}>{errors.email}</span>}
                    </div>
                    <div className="col col-lg-2">
                    <input type="text" 
                        name="code" 
                        required
                        id="code"
                        placeholder="+(222)" 
                        onChange={this.handleTextChange} />
                        {/* <i className="fa fa-phone"></i> */}
                        {errors.code.length > 0 && 
                        <span style={{color:"#dc3545"}}>{errors.code}</span>}
                    </div>
                    <div className="col col-lg-4">
                    <input type="text" 
                        name="phone" 
                        required
                        id="phone"
                        placeholder={i18n.t('contact-phone-label')} 
                        onChange={this.handleTextChange} />
                        {/* <i className="fa fa-phone"></i> */}
                        {errors.phone.length > 0 && 
                        <span style={{color:"#dc3545"}}>{errors.phone}</span>}
                    </div>
                    <div className="col-12  col-lg-6">
                    <input type="text" name="subject" 
                        id="subject" placeholder={i18n.t('contact-subject-label')} 
                        required
                        onChange={this.handleTextChange} />
                        {/* <i className="fas fa-edit"></i> */}
                        {errors.subject.length > 0 && 
                        <span style={{color:"#dc3545"}}>{errors.subject}</span>}
                    </div>
                    <div className="col-12 col-sm-12">
                    <textarea 
                        name="message" 
                        id="message" 
                        placeholder={i18n.t('contact-message-label')}
                        required
                        onChange={this.handleTextChange}
                        ></textarea>
                        {/* <i className="fas fa-edit"></i> */}
                        {errors.message.length > 0 && 
                            <span style={{color:"#dc3545"}}>{errors.message}</span>}
                    </div>
                    <div className="col-12">
                        <button type="submit" className="theme-btn">{i18n.t('contact-appointment-label')}</button>
                        {this.state.loading ?
                            <div style={{marginRight:"20px",marginTop:"10px",textAlign:"center"}}>
                                <Spinner animation="grow" variant="secondary" />
                                <Spinner animation="grow" variant="danger" />
                                <Spinner animation="grow" variant="warning" />
                                </div>:''
                        }
                    </div>
                </div>
                <div className="modal-div">
                    <Modal show={this.state.modal} handleClose={e => this.modalClose(e)}>
                    <h4 className="contact-title" style={{marginTop:"20px"}}>{i18n.t('contact-description-two-label')}</h4>
                    <div className="form-group" style={{marginTop:"30px"}}>
                        {this.state.success ?
                        <ul style={{listStyleType:"none"}}>
                            <span><i className="fa fa-check" style={{color:"green",marginRight:"10px"}}></i>{i18n.t('success-contact-api-label')}</span>
                        </ul>
                        :(dataErrors.length?
                        <ul style={{listStyleType:"none"}}>
                             {Object.keys(dataErrors).map(function(keyName, keyIndex) {
                                return (
                                    <li key={keyName} style={{textAlign:"left"}}><i className="fa fa-exclamation-triangle" style={{color:"red",marginRight:"10px"}}></i>
                                    {keyName=="name"?"Nom complet":keyName.charAt(0).toUpperCase() + keyName.slice(1)} :
                                            {dataErrors[keyName]}
                                        </li>
                                )
                            })}
                        </ul>
                        :<span><i className="fa fa-exclamation-triangle" style={{color:"red",marginRight:"10px"}}></i>{i18n.t('error-contact-api-label')}</span>
                        )}
                    </div>
                    </Modal>
                </div>


            </form>
        )
    }
}

export default ContactForm2;