import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from '../../../components/Navbar'
import Breadcumb from '../../../components/breadcumb'

import FooterSection from '../../../components/Footer'

import pr_1 from '../../../images/logo/web_655_logo_n-logo.png';
import pr_2 from '../../../images/logo/web_655_logo_n-logo.png';
import pr_3 from '../../../images/logo/web_655_logo_n-logo.png';

import i18next from 'i18next';
import Parser from 'html-react-parser';
import i18n from './../../../i18n';
class TrainingSection extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        var settings = {
            dots: true,
            arrows: true,
            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay:true,
            autoplaySpeed:5500,
            fade:true
        };
        return (
            <div>
           <Navbar/>
           <Breadcumb bdtitle={i18n.t('practice-label')} bdsub={i18n.t('practice-detail-label')}/>
            <div className="practice-details-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-12 p-p">
                            <div className="row">
                                <div className="col-lg-12 col-md-6 col-12">
                                    <div className="field-section">
                                        {/* <div className="field-img"> */}
                                        <div>
                                            <img src={pr_1} alt=""/>
                                        </div>
                                        {/* <div className="field-content">
                                            <h3><span>25</span>Years of Experience In This Field</h3>
                                            <div className="btns">
                                                <div className="btn-style"><Link to="/contact">Contact Us Now</Link></div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6 col-12">
                                    <div className="practice-catagory-item">
                                        <div className="widget-title">
                                            <h3 className="text-left">{i18n.t('practice-title-description-label')}</h3>
                                        </div>
                                        <div className="practice-section">
                                            <ul>
                                                <li><Link to="/telecom">{i18n.t('practice-telecom-pole-label')}</Link></li>
                                                <li><Link to="/computer-science">{i18n.t('practice-computer-science-label')}</Link></li>
                                                <li><Link to="/software">{i18n.t('practice-software-label')}</Link></li>
                                                <li><Link to="/training" style={{color: "#15729f"}}>{i18n.t('practice-certification-label')}</Link></li>
                                                <li><Link to="/solutions">{i18n.t('practice-solution-label')}</Link></li>
                                                <li><Link to="/support">{i18n.t('practice-support-label')}</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-12">
                            {/* <div className="practice-section-img">
                                <img src={pr_2} alt=""/>
                            </div> */}
                            <div className="practice-section-text">
                                <h2>{i18n.t('practice-certification-label')}</h2>
                                <p className="justify-text">{Parser(i18n.t('training-part-one-label'))}</p>
                            </div>
                            <div className="organigation-area">
                                {/* <div className="organaigation-img">
                                    <img src={pr_3} alt=""/>
                                </div> */}
                                <div className="organigation-text">
                                    <h2> {i18n.t('training-part-two-label')}</h2>
                                    <h5>{i18n.t('training-telecom-label')}:</h5>
                                    {Parser(i18n.t('training-sub-list-one-label'))}
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-one-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-two-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-three-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-four-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-five-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-six-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-seven-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-height-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-nine-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-ten-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-eleven-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-twelve-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-thirteen-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-fourteen-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-fifteen-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-sixteen-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-seventeen-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-heighteen-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-nineteen-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-twenty-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-twentyone-label')}</span>
                                </div>
                            </div>
                            <div className="organigation-area">
                                <div className="organigation-text">
                                    <h5>{i18n.t('training-computer-label')}:</h5>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-computer-one-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-computer-two-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-computer-three-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-computer-four-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-computer-five-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-computer-six-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-computer-seven-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-computer-height-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-computer-nine-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-computer-ten-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('training-sub-list-computer-eleven-label')}</span>
                                </div>
                            </div>
                            <div className="practice-section-text">
                                <h3>{i18n.t('training-part-three-label')}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterSection/>
       </div>

        );
    }
}

export default TrainingSection;