import React, { Component } from "react";
import Slider from "react-slick";
import {Link} from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Parser from 'html-react-parser';

import './style.css'
import i18next from 'i18next';
import i18n from './../../i18n';
class SimpleSlider extends Component {
    render() {
        var settings = {
            dots: true,
            arrows: true,
            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay:true,
            autoplaySpeed:7000,
            fade:true
        };
      return (
        <section className="hero hero-slider-wrapper hero-style-1">
            <div className="hero-slider">
            <Slider {...settings}>
                <div className="slide1 slide">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col col-lg-8 slide-caption"> */}
                            <ul className="slide1-list">
                                <li style={{minWidth:"400px"}}>
                                    <div className="col-lg-9 slide-caption">
                                        <h2 className="one">{i18n.t('it-service-one-label')}</h2>
                                        <h2 className="two">{i18n.t('it-service-two-label')}</h2>
                                        <p className="three" style={{fontFamily:"sans-serif"}}>{Parser(i18n.t('it-service-three-label'))}</p>
                                        <div className="btns four">
                                            <div className="btn-style"><a href="/#contact-section">{i18n.t('contact-us-label')}</a></div>
                                        </div>
                                    </div>
                                </li>
                                <li style={{marginRight:"20%"}}>
                                    <div style={{height:"auto"}}>
                                        <div className="dot1 zoom1 child2-circle slide-1-circle1" >
                                            <div style={{marginTop:"20px"}}>{Parser(i18n.t('slide1-description-1'))}</div>
                                        </div>
                                        <div className="dot-in1 zoom2 child1-circle slide-1-circle2" style={{fontSize:"20px",color:"#858585"}}>{Parser(i18n.t('slide1-description-2'))}</div>
                                    </div>
                                </li>
                            </ul>
                            
                            
                        </div>
                    </div>
                </div>
                <div className="slide2 slide">
                    <div className="container">
                        <div className="row">
                            <ul className="slide1-list" style={{width:"100%"}}>
                                <li style={{width:"40%",marginTop:"10%",marginLeft:"10%"}}>
                                    <div className="dot2 zoom1 slide-2-circle1">
                                        <div style={{marginTop:"20px"}}>{Parser(i18n.t('slide2-description-1'))}</div>
                                    </div>
                                </li>
                                <li style={{textAlign:"right"}} className="list-slide-2">
                                    <div className="col col-lg-12 slide-caption">
                                        <h2 style={{textAlign:"right"}} className="one second-li-1">{i18n.t('competence-one-label')}</h2>
                                        <h2 style={{textAlign:"right"}} className="two second-li-2">{i18n.t('competence-two-label')}</h2>
                                        <h3 style={{textAlign:"right",color:"white"}} className="three second-li-3">{i18n.t('competence-three-label')}</h3>
                                        <p></p>
                                        <div className="btns four" style={{textAlign:"right"}}>
                                            <div className="btn-style"><a href="/#contact-section">{i18n.t('contact-us-label')}</a></div>
                                        </div>
                                    </div>
                                </li>
                            </ul> 
                        </div>
                    </div>
                </div>
                <div className="slide3 slide">
                    <div className="container">
                        <div className="row">
                            <ul className="slide3-list" style={{width:"100%"}}>
                                <li className="element-list1">
                                    <div className="col col-lg-10 slide-caption">
                                        <h2 className="one">{Parser(i18n.t('ingeneering-label'))}</h2>
                                        <ul className="two">
                                            <li className="li-list">
                                                <i className="fa fa-check">{i18n.t('ingennering-list-one-label')}</i>
                                            </li>
                                            <li className="li-list">
                                                <i className="fa fa-check">{i18n.t('ingennering-list-two-label')}</i>
                                            </li>
                                            <li className="li-list">
                                                <i className="fa fa-check">{i18n.t('ingennering-list-three-label')}</i>
                                            </li>
                                            <li className="li-list">
                                                <i className="fa fa-check">{i18n.t('ingennering-list-four-label')}</i>
                                            </li>
                                        </ul>
                                        <div className="btns four">
                                            <div className="btn-style"><a href="/#contact-section">{i18n.t('contact-us-label')}</a></div>
                                        </div>
                                    </div>
                                </li>
                                <li style={{marginRight:"20%",marginTop:"10%"}}>
                                    <div style={{height:"auto"}}>
                                        <div className="dot3 zoom1 child2-circle slide-3-circle1">
                                            <div style={{marginTop:"20px"}}>{Parser(i18n.t('slide3-description-1'))}</div>
                                        </div>
                                        <span className="dot-in2 zoom2 child1-circle slide-3-circle2">
                                        <div style={{marginTop:"20px",fontSize:"20px",color:"#858585"}}>{Parser(i18n.t('slide3-description-2'))}</div>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* <div className="slide4 slide">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-8 slide-caption">
                                {Parser(i18n.t('solution-label'))}
                                <div className="btns">
                                    <div className="btn-style"><Link to="/#contact-section">{i18n.t('contact-us-label')}</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slide5 slide">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-8 slide-caption">
                                {Parser(i18n.t('support-label'))}
                                <div className="btns">
                                    <div className="btn-style"><Link to="/#contact-section">{i18n.t('contact-us-label')}</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                </Slider>
            </div>
        </section>
      );
    }
  }

export default SimpleSlider;