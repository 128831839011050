import React from 'react';

import pr1 from '../../images/icon/phone-icon-blue.png'
import pr2 from '../../images/icon/connexion.png'
import pr3 from '../../images/icon/software.png'
import pr4 from '../../images/icon/training.png'
import pr5 from '../../images/icon/solution.png'
import pr6 from '../../images/icon/support.png'
import Parser from 'html-react-parser';
import i18next from 'i18next';
import i18n from './../../i18n';
import './style.css'

const PracticeArea = ( props ) => {

    return(
        <div id="practice-section" className={`practice-area  ptb-100-70 ${props.subclass}`}>
            <div className="container">
                <div className="col-12">
                    <div className="section-title-1 text-center">
                        <span>{i18n.t('practice-title-main-label')}</span>
                        <h2>{i18n.t('practice-title-description-label')}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="practice-section">
                            <div className="practices-wrapper">
                                <div className="practices-icon-wrapper">
                                    <div className="practice-dot">
                                        <div className="dots"></div>
                                    </div>
                                    <i className="fi"><img src={pr1} alt=""/></i>
                                </div>
                                <div className="practice-content">
                                    <h2>{i18n.t('practice-telecom-pole-label')}</h2>
                                    <p>{i18n.t('practice-telecom-pole-description-label')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="practice-section">
                            <div className="practices-wrapper">
                                <div className="practices-icon-wrapper">
                                    <div className="practice-dot">
                                        <div className="dots dots-2"></div>
                                    </div>
                                    <i className="fi"><img src={pr2} alt=""/></i>
                                </div>
                                <div className="practice-content">
                                    <h2>{i18n.t('practice-computer-science-label')}</h2>
                                    <p>{i18n.t('practice-computer-science-description-label')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="practice-section">
                            <div className="practices-wrapper">
                                <div className="practices-icon-wrapper">
                                    <div className="practice-dot">
                                        <div className="dots"></div>
                                    </div>
                                    <i className="fi"><img src={pr3} alt=""/></i>
                                </div>
                                <div className="practice-content">
                                    <h2>{i18n.t('practice-software-label')}</h2>
                                    <p>{i18n.t('practice-software-description-label')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="practice-section">
                            <div className="practices-wrapper">
                                <div className="practices-icon-wrapper">
                                    <div className="practice-dot">
                                        <div className="dots"></div>
                                    </div>
                                    <i className="fi"><img src={pr4} alt=""/></i>
                                </div>
                                <div className="practice-content">
                                    <h2>{i18n.t('practice-certification-label')}</h2>
                                    <p>{i18n.t('practice-certification-description-label')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="practice-section">
                            <div className="practices-wrapper">
                                <div className="practices-icon-wrapper">
                                    <div className="practice-dot">
                                        <div className="dots"></div>
                                    </div>
                                    <i className="fi"><img src={pr5} alt=""/></i>
                                </div>
                                <div className="practice-content">
                                    <h2>{i18n.t('practice-solution-label')}</h2>
                                    <p>{i18n.t('practice-solution-description-label')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="practice-section">
                            <div className="practices-wrapper">
                                <div className="practices-icon-wrapper">
                                    <div className="practice-dot">
                                        <div className="dots"></div>
                                    </div>
                                    <i className="fi"><img src={pr6} alt=""/></i>
                                </div>
                                <div className="practice-content">
                                    <h2>{i18n.t('practice-support-label')}</h2>
                                    <p>{i18n.t('practice-support-description-label')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default PracticeArea;
