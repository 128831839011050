import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import './style.css'

import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/breadcumb'

import FooterSection from '../../components/Footer'

import pr_1 from '../../images/logo/web_655_logo_n-logo.png';
import pr_2 from '../../images/logo/web_655_logo_n-logo.png';
import pr_3 from '../../images/logo/web_655_logo_n-logo.png';
import i18next from 'i18next';
import Parser from 'html-react-parser';
import i18n from './../../i18n';

class PracticeDetails extends Component {
    state={
        show:false
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        return (
            <div >
                {/* <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-12 p-p">
                            <div className="row">
                                <div className="col-lg-12 col-md-6 col-12">
                                    <div className="field-section"> */}
                                        {/* <div className="field-img"> */}
                                        {/* <div>
                                            <img src={pr_1} alt=""/>
                                        </div> */}
                                        {/* <div className="field-content">
                                            <h3><span>25</span>Years of Experience In This Field</h3>
                                            <div className="btns">
                                                <div className="btn-style"><Link to="/contact">Contact Us Now</Link></div>
                                            </div>
                                        </div> */}
                                    {/* </div>
                                </div>
                                <div className="col-lg-12 col-md-6 col-12">
                                    <div className="practice-catagory-item">
                                        <div className="widget-title">
                                            <h3 className="text-left">OUR AREAS OF EXPERTISE</h3>
                                        </div>
                                        <div className="practice-section">
                                            <ul>
                                                <li><Link to="/practice-details">Telecom Pole</Link></li>
                                                <li><Link to="/practice-details">Computer sciences programm</Link></li>
                                                <li><Link to="/practice-details">Software and Web Application</Link></li>
                                                <li><Link to="/practice-details">Training & Certification</Link></li>
                                                <li><Link to="/practice-details">Solutions</Link></li>
                                                <li><Link to="/practice-details">24/7 technical support</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-12"> */}
                            {/* <div className="practice-section-img">
                                <img src={pr_2} alt=""/>
                            </div> */}
                            {/* <div className="practice-section-text">
                                <h2>Family Law</h2>
                                <h5>I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born </h5>
                                <p>I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, </p>
                                <p>because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? </p>
                            </div>
                            <div className="organigation-area">
                                <div className="organaigation-img">
                                    <img src={pr_3} alt=""/>
                                </div>
                                <div className="organigation-text">
                                    <h2>Family Law Organizations</h2>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>The master-builder of human happiness.</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Occasionally circumstances occur in which toil and pain</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>Avoids pleasure itself, because it is pleasure</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>who do not know how to pursue pleasure</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>To take a trivial example, which of us ever undertakes</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <Navbar/>
           <Breadcumb bdtitle={i18n.t('practice-label')} bdsub={i18n.t('practice-detail-label')}/>
            <div id="details-section" className="practice-details-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-12 p-p">
                            <div className="row">
                                <div className="col-lg-12 col-md-6 col-12">
                                    <div className="field-section">
                                        {/* <div className="field-img"> */}
                                        <div>
                                            <img src={pr_1} alt=""/>
                                        </div>
                                        {/* <div className="field-content">
                                            <h3><span>25</span>Years of Experience In This Field</h3>
                                            <div className="btns">
                                                <div className="btn-style"><Link to="/contact">Contact Us Now</Link></div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6 col-12">
                                    <div className="practice-catagory-item">
                                        <div className="widget-title">
                                            <h3 className="text-left">{i18n.t('practice-title-description-label')}</h3>
                                        </div>
                                        <div className="practice-section">
                                            <ul>
                                            <li><Link to="/telecom" style={{color: "#15729f"}}>{i18n.t('practice-telecom-pole-label')}</Link></li>
                                                <li><Link to="/computer-science">{i18n.t('practice-computer-science-label')}</Link></li>
                                                <li><Link to="/software">{i18n.t('practice-software-label')}</Link></li>
                                                <li><Link to="/training">{i18n.t('practice-certification-label')}</Link></li>
                                                <li><Link to="/solutions">{i18n.t('practice-solution-label')}</Link></li>
                                                <li><Link to="/support">{i18n.t('practice-support-label')}</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-12">
                            {/* <div className="practice-section-img">
                                <img src={pr_2} alt=""/>
                            </div> */}
                            <div className="practice-section-text">
                                <h2>{i18n.t('practice-telecom-pole-label')}</h2>
                                {Parser(i18n.t('telecom-part-one-label'))}
                            </div>
                            {/* <div className="organigation-area"> */}
                                {/* <div className="organaigation-img">
                                    <img src={pr_3} alt=""/>
                                </div> */}
                                <div className="organigation-text">
                                    {/* <h2>Family Law Organizations</h2> */}
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('telecom-service-one-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('telecom-service-two-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('telecom-service-three-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('telecom-service-four-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('telecom-service-five-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('telecom-service-six-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('telecom-service-seven-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('telecom-service-height-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('telecom-service-nine-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('telecom-service-ten-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('telecom-service-eleven-label')}</span>

                                </div>
                            {/* </div> */}
                        </div>
                        <div>
                            {
                            this.state.show?  
                            <div><p></p>{Parser(i18n.t('telecom-part-two-label'))}
                                <h2>
                                    {i18n.t('engineer-label')}
                                </h2>
                                <h5>{i18n.t('engineer-title-label')}</h5>
                                <p>{i18n.t('engineer-description-section-label')}</p>
                                <div className="organigation-text">
                                    {/* <h2>Family Law Organizations</h2> */}
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('engineer-section-element-one-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('engineer-section-element-two-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('engineer-section-element-three-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('engineer-section-element-four-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('engineer-section-element-five-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('engineer-section-element-six-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('engineer-section-element-seven-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('engineer-section-element-height-label')}</span>
                                </div>
                                <p>{i18n.t('engineer-download-label')}</p>
                                <h2>
                                    {i18n.t('service-solution-label')}
                                </h2>
                                <h5>
                                    {i18n.t('service-solution-subtitle-label')}
                                </h5>
                                {Parser(i18n.t('service-solution-description-label'))}
                                <h6 className="title-element">{i18n.t('service-solution-first-list-title-label')}</h6>
                                <div className="organigation-text">
                                    {/* <h2>Family Law Organizations</h2> */}
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-first-list-one-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-first-list-two-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-first-list-three-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-first-list-four-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-first-list-five-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-first-list-six-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-first-list-seven-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-first-list-height-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-first-list-nine-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-first-list-ten-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-first-list-eleven-label')}</span>
                                </div>

                                <br/><h6 className="title-element">{i18n.t('service-solution-two-list-title-label')}</h6>
                                <div className="organigation-text">
                                    {/* <h2>Family Law Organizations</h2> */}
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-1-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-2-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-3-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-4-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-5-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-6-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-7-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-8-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-9-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-10-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-11-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-12-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-13-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-14-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-15-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-16-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-17-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-18-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-19-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-20-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-21-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-22-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-23-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-24-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-25-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-26-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-27-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-28-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-29-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-30-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-31-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-32-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-33-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-34-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-35-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-two-list-36-label')}</span>
                                </div>

                                <br/><h6 className="title-element">{i18n.t('service-solution-three-list-title-label')}</h6>
                                <div className="organigation-text">
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-three-list-1-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-three-list-2-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-three-list-3-label')}</span>
                                </div>

                                <br/><h6 className="title-element">{i18n.t('service-solution-four-list-title-label')}</h6>
                                <div className="organigation-text">
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-four-list-1-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-four-list-2-label')}</span>
                                </div>

                                <br/><h6 className="title-element">{i18n.t('service-solution-five-list-title-label')}</h6>
                                <div className="organigation-text">
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-five-list-1-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-five-list-2-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-five-list-3-label')}</span>
                                </div>

                                <br/><h6 className="title-element">{i18n.t('service-solution-six-list-title-label')}</h6>
                                <div className="organigation-text">
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-six-list-1-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('service-solution-six-list-2-label')}</span>
                                </div>
                                <div style={{marginTop:"20px"}}><a href="/#contact-section" >{i18n.t('reply-label')}</a></div>

                            </div>: null}
                        </div>
                        <div style={{marginTop:"20px"}}>
                            <div className="btns">
                                <div className="btn-style" onClick={()=>{this.setState({show:!this.state.show})}}><Link>{this.state.show? i18n.t('hide-label'): i18n.t('show-label')}</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterSection/>
            </div>

        );
    }
}

export default PracticeDetails;