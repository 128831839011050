import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/breadcumb'

import Clients from '../../components/Team'
import FooterSection from '../../components/Footer'
import i18next from 'i18next';
import i18n from './../../i18n';

const TeamList = () => {
    return(
       <div>
           <Navbar/>
           <Breadcumb bdtitle={i18n.t('client-label')} bdsub={i18n.t('client-recent-label')}/>
           <Clients/>
           <FooterSection/>
       </div>
    )
}

export default TeamList;