import React, { Component } from 'react';
import {Link} from 'react-router-dom'

import Navbar from '../../../components/Navbar'
import Breadcumb from '../../../components/breadcumb'

import FooterSection from '../../../components/Footer'

import pr_1 from '../../../images/logo/web_655_logo_n-logo.png';
import pr_2 from '../../../images/logo/web_655_logo_n-logo.png';
import pr_3 from '../../../images/logo/web_655_logo_n-logo.png';

import i18next from 'i18next';
import Parser from 'html-react-parser';
import i18n from './../../../i18n';
class SoftwareSection extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        return (
            <div>
            <Navbar/>
            <Breadcumb bdtitle={i18n.t('practice-label')} bdsub={i18n.t('practice-detail-label')}/>
            <div className="practice-details-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-12 p-p">
                            <div className="row">
                                <div className="col-lg-12 col-md-6 col-12">
                                    <div className="field-section">
                                        {/* <div className="field-img"> */}
                                        <div>
                                            <img src={pr_1} alt=""/>
                                        </div>
                                        {/* <div className="field-content">
                                            <h3><span>25</span>Years of Experience In This Field</h3>
                                            <div className="btns">
                                                <div className="btn-style"><Link to="/contact">Contact Us Now</Link></div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6 col-12">
                                    <div className="practice-catagory-item">
                                        <div className="widget-title">
                                            <h3 className="text-left">{i18n.t('practice-title-description-label')}</h3>
                                        </div>
                                        <div className="practice-section">
                                            <ul>
                                                <li><Link to="/telecom">{i18n.t('practice-telecom-pole-label')}</Link></li>
                                                <li><Link to="/computer-science">{i18n.t('practice-computer-science-label')}</Link></li>
                                                <li><Link to="/software" style={{color: "#15729f"}}>{i18n.t('practice-software-label')}</Link></li>
                                                <li><Link to="/training">{i18n.t('practice-certification-label')}</Link></li>
                                                <li><Link to="/solutions">{i18n.t('practice-solution-label')}</Link></li>
                                                <li><Link to="/support">{i18n.t('practice-support-label')}</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-12">
                            {/* <div className="practice-section-img">
                                <img src={pr_2} alt=""/>
                            </div> */}
                            <div className="practice-section-text">
                                <h2>{i18n.t('practice-software-label')}</h2>
                                {Parser(i18n.t('software-part-one-label'))}
                                <p className="justify-text">{Parser(i18n.t('software-part-one-description-1-label'))}</p>
                                <p className="justify-text">{Parser(i18n.t('software-part-one-description-2-label'))}</p>
                                <div className="organigation-text">
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('software-list-one-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('software-list-two-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('software-list-three-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('software-list-four-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('software-list-five-label')}</span>
                                    <span><i className="fa fa-check-square-o" aria-hidden="true"></i>{i18n.t('software-list-six-label')}</span>
                                </div>
                            </div>
                            <div className="organigation-area">
                                {/* <div className="organaigation-img">
                                    <img src={pr_3} alt=""/>
                                </div> */}
                                <div className="organigation-text">
                                    {Parser(i18n.t('software-part-two-label'))}
                                    <p className="justify-text">{Parser(i18n.t('software-part-two-description-1-label'))}</p>
                                    <p className="justify-text">{Parser(i18n.t('software-part-two-description-2-label'))}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  <FooterSection/>
  </div>
        );
    }
}

export default SoftwareSection;