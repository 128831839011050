import React from 'react';
import AllRoute from '../router'
import './App.css';
import Ticket from '../../components/Ticket'
// import Back from '../../components/Back'
import ScrollButton from '../../components/ScrollToTop'

import Homepage from '../HomePage'
import AboutPage from '../AboutPage'
import PracticePage from '../PracticePage'
import CaseStadies from '../CaseStadies'
import ContactPage from '../ContactPage'


const App = () => { 

  return (
    <div className="App">
      <AllRoute/>
      <Ticket/>
      <ScrollButton/>
    </div>
  );
}

export default App;
