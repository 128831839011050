import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from './i18n/translations/en.json';
import translationFR from './i18n/translations/fr.json';
const locales = ['en', 'fr'];
let defaultLanguage = "en";
 
// the translations
const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  }
};
 
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLanguage,
    locales,
    keySeparator:false,  // to support nested translations
 
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
 
  export default i18n;