import React, { Component } from 'react';
import Slider from "react-slick";
import {Link} from 'react-router-dom'
import './style.css'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import team_1 from '../../images/team/clients/1.png';
import team_2 from '../../images/team/clients/2.png';
import team_3 from '../../images/team/clients/3.png';
import team_4 from '../../images/team/clients/4.png';
import team_5 from '../../images/team/clients/5.png';
import team_6 from '../../images/team/clients/6.png';

import team_7 from '../../images/team/clients/7.png';
import team_8 from '../../images/team/clients/8.png';
import team_9 from '../../images/team/clients/9.png';
import team_10 from '../../images/team/clients/10.png';
import team_11 from '../../images/team/clients/11.png';
import team_12 from '../../images/team/clients/12.png';

import team_13 from '../../images/team/clients/13.png';
import team_14 from '../../images/team/clients/14.png';
import team_15 from '../../images/team/clients/15.png';
import team_16 from '../../images/team/clients/16.png';
import team_17 from '../../images/team/clients/17.png';
import team_18 from '../../images/team/clients/18.png';

import team_19 from '../../images/team/clients/19.png';
import team_20 from '../../images/team/clients/20.png';
// import team_21 from '../../images/team/3381_header-logo-total.png';
// import team_22 from '../../images/team/3381_ARIAMIS.jpg';
// import team_23 from '../../images/team/3381_CANAL.png';
// import team_24 from '../../images/team/3381_LOGOHCZ.jpg';

// import team_25 from '../../images/team/3381_JOBMEDIA.png';
// import team_26 from '../../images/team/3381_images10.jpg';
// import team_27 from '../../images/team/3381_LOGO_BESTCALL_SFAX.png';
// import team_28 from '../../images/team/3381_LOGO_AMB_USA.jpg';
// import team_29 from '../../images/team/3381_LOGO_RADIO_NOUAKCHOTT.jpg';
// import team_30 from '../../images/team/3381_LOGO_ALCATEL.jpg';

// import team_31 from '../../images/team/3381_logo_traiteurtunisie.png';
// import team_32 from '../../images/team/3381_Logo-Azalai-Group.png';
import i18next from 'i18next';
import i18n from './../../i18n';
import Parser from 'html-react-parser';
class TeamSection extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 1200,
            slidesToShow: 1,
            autoplay:true,
            arrows: true,
            margin:50,
            slidesToScroll: 1,
            centerPadding: 30,
            focusOnSelect: false,

        };
        return (
            
            <div id="clients-section" className="team-area ptb-100-70">
                <div className="container">
                    <div className="col-l2">
                        <div className="section-title-1  text-center">
                            <span>{i18n.t('client-title-label')}</span>
                            <h2>{i18n.t('client-title-description-label')}</h2>
                        </div>
                    </div>
                    <div className="team-active owl-carousel">
                    <Slider {...settings}>
                        <div className="team-item">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="team-single">
                                        <div className="team-img">
                                            <img src={team_1} alt=""/>
                                            {/* <div className="social-1st">
                                                <ul>
                                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="team-content">
                                            <h4>Lily Watson</h4>
                                            <span>Family Lawyer</span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="team-single">
                                        <div className="team-img">
                                            <img src={team_2} alt=""/>
                                            {/* <div className="social-1st">
                                                <ul>
                                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="team-content">
                                            <h4>Willam Stephen</h4>
                                            <span>Criminal Lawyer</span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="team-single">
                                        <div className="team-img">
                                             <img src={team_3} alt=""/>
                                            {/* <div className="social-1st">
                                                <ul>
                                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="team-content">
                                            <h4>Eshan Golly</h4>
                                            <span>Business Lawyer</span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="team-single">
                                        <div className="team-img">
                                            <img src={team_4} alt=""/>
                                            {/* <div className="social-1st">
                                                <ul>
                                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="team-content">
                                            <h4>Daniel Dambeldor</h4>
                                            <span>Family Lawyer</span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="team-item">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="team-single">
                                        <div className="team-img">
                                            <img src={team_5} alt=""/>
                                            {/* <div className="social-1st">
                                                <ul>
                                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="team-content">
                                            <h4>Darcy Alec</h4>
                                            <span>Criminal Lawyer</span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="team-single">
                                        <div className="team-img">
                                            <img src={team_6} alt=""/>
                                            {/* <div className="social-1st">
                                                <ul>
                                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="team-content">
                                            <h4>Alecgander Harry</h4>
                                            <span>Business Lawyer</span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="team-single">
                                        <div className="team-img">
                                            <img src={team_7} alt=""/>
                                            {/* <div className="social-1st">
                                                <ul>
                                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="team-content">
                                            <h4>Daniel Dambeldor</h4>
                                            <span>Family Lawyer</span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="team-single">
                                        <div className="team-img">
                                            <img src={team_8} alt=""/>
                                            {/* <div className="social-1st">
                                                <ul>
                                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="team-content">
                                            <h4>Darcy Alec</h4>
                                            <span>Criminal Lawyer</span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="team-item">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="team-single">
                                        <div className="team-img">
                                            <img src={team_9} alt=""/>
                                            {/* <div className="social-1st">
                                                <ul>
                                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="team-content">
                                            <h4>Alecgander Harry</h4>
                                            <span>Business Lawyer</span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="team-single">
                                        <div className="team-img">
                                            <img src={team_10} alt=""/>
                                            {/* <div className="social-1st">
                                                <ul>
                                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="team-content">
                                            <h4>Daniel Dambeldor</h4>
                                            <span>Family Lawyer</span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="team-single">
                                        <div className="team-img">
                                            <img src={team_11} alt=""/>
                                            {/* <div className="social-1st">
                                                <ul>
                                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="team-content">
                                            <h4>Darcy Alec</h4>
                                            <span>Criminal Lawyer</span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="team-single">
                                        <div className="team-img">
                                            <img src={team_12} alt=""/>
                                            {/* <div className="social-1st">
                                                <ul>
                                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="team-content">
                                            <h4>Alecgander Harry</h4>
                                            <span>Business Lawyer</span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="team-item">
                            <div className="row">
                            <div className="col-lg-3 col-md-6 col-12">
                                    <div className="team-single">
                                        <div className="team-img">
                                            <img src={team_13} alt=""/>
                                            {/* <div className="social-1st">
                                                <ul>
                                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="team-content">
                                            <h4>Daniel Dambeldor</h4>
                                            <span>Family Lawyer</span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="team-single">
                                        <div className="team-img">
                                            <img src={team_14} alt=""/>
                                            {/* <div className="social-1st">
                                                <ul>
                                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="team-content">
                                            <h4>Darcy Alec</h4>
                                            <span>Criminal Lawyer</span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="team-single">
                                        <div className="team-img">
                                            <img src={team_15} alt=""/>
                                            {/* <div className="social-1st">
                                                <ul>
                                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="team-content">
                                            <h4>Alecgander Harry</h4>
                                            <span>Business Lawyer</span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="team-single">
                                        <div className="team-img">
                                            <img src={team_16} alt=""/>
                                            {/* <div className="social-1st">
                                                <ul>
                                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="team-content">
                                            <h4>Alecgander Harry</h4>
                                            <span>Business Lawyer</span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="team-item">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="team-single">
                                        <div className="team-img">
                                            <img src={team_17} alt=""/>
                                            {/* <div className="social-1st">
                                                <ul>
                                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="team-content">
                                            <h4>Alecgander Harry</h4>
                                            <span>Business Lawyer</span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="team-single">
                                        <div className="team-img">
                                            <img src={team_18} alt=""/>
                                            {/* <div className="social-1st">
                                                <ul>
                                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="team-content">
                                            <h4>Alecgander Harry</h4>
                                            <span>Business Lawyer</span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="team-single">
                                        <div className="team-img">
                                            <img src={team_19} alt=""/>
                                            {/* <div className="social-1st">
                                                <ul>
                                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="team-content">
                                            <h4>Alecgander Harry</h4>
                                            <span>Business Lawyer</span>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="team-single">
                                        <div className="team-img">
                                            <img src={team_20} alt=""/>
                                            {/* <div className="social-1st">
                                                <ul>
                                                    <li><Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                                    <li><Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        {/* <div className="team-content">
                                            <h4>Alecgander Harry</h4>
                                            <span>Business Lawyer</span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
        );
    }
}

export default TeamSection;            