import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import './style.css'
import Gallery_1 from '../../images/studies/partners/1.png';
import Gallery_2 from '../../images/studies/partners/2.png';
import Gallery_3 from '../../images/studies/partners/3.png';
import Gallery_4 from '../../images/studies/partners/4.png';
import Gallery_5 from '../../images/studies/partners/5.png';
import Gallery_6 from '../../images/studies/partners/6.png';
import Gallery_7 from '../../images/studies/partners/7.png';
import Gallery_8 from '../../images/studies/partners/8.png';
import Gallery_9 from '../../images/studies/partners/9.png';
import Gallery_10 from '../../images/studies/partners/10.png';
import Gallery_11 from '../../images/studies/partners/11.png';
import Gallery_12 from '../../images/studies/partners/12.png';
import Gallery_13 from '../../images/studies/partners/13.png';
import Gallery_14 from '../../images/studies/partners/14.png';
import Gallery_15 from '../../images/studies/partners/15.png';
import Gallery_16 from '../../images/studies/partners/16.png';
import Gallery_17 from '../../images/studies/partners/17.png';
import Gallery_18 from '../../images/studies/partners/18.png';
import Gallery_19 from '../../images/studies/partners/19.png';
import Gallery_20 from '../../images/studies/partners/20.png';
import Gallery_21 from '../../images/studies/partners/21.png';
import Gallery_22 from '../../images/studies/partners/22.png';
import Gallery_23 from '../../images/studies/partners/23.png';
import Gallery_24 from '../../images/studies/partners/24.png';
import Gallery_25 from '../../images/studies/partners/25.png';
import Gallery_26 from '../../images/studies/partners/26.png';
import Gallery_27 from '../../images/studies/partners/27.png';
import Gallery_28 from '../../images/studies/partners/28.png';
import Gallery_29 from '../../images/studies/partners/29.png';
import Gallery_30 from '../../images/studies/partners/30.png';
import Gallery_31 from '../../images/studies/partners/31.png';
import Gallery_32 from '../../images/studies/partners/32.png';
import Gallery_33 from '../../images/studies/partners/33.png';
import i18next from 'i18next';
import i18n from './../../i18n';
class Porfolio extends Component {

    state = {
        onWebDesign: false,
        onPrintDesign: false,
        onWebApp: false,
        onPhotography: false,
        isOpen : false,
    }

    render() {

        const allElement = event => {
            event.preventDefault();
            this.setState({
                onWebApp: true,
                onPrintDesign: true,
                onWebDesign: true,
                onPhotography: true,
            });
        }

        const webDesign = event => {
            event.preventDefault();
            this.setState({
                onWebDesign: true,
                onPrintDesign: false,
                onWebApp: false,
                onPhotography: true,
                isOpen: true,
            });
        }

        const printDesign = event => {
            event.preventDefault();
            this.setState({
                onPrintDesign: true,
                onWebDesign: false,
                onWebApp: false,
                onPhotography: false,
                isOpen: true,
            });
        }

        const webApp = event => {
            event.preventDefault();
            this.setState({
                onWebApp: true,
                onPrintDesign: false,
                onWebDesign: false,
                onPhotography: false,
                isOpen: true,
            });
        }

        const Photography = event => {
            event.preventDefault();
            this.setState({
                onPhotography: true,
                onWebApp: false,
                onPrintDesign: false,
                onWebDesign: false,
                isOpen: true,
            });
        }



        return (
            <section id="partners-section" className="gallery-section studies-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12 sortable-gallery">
                            <div className="gallery-filters">
                                <div className="section-title-1 text-center">
                                    <span>{i18n.t('partner-title-label')}</span>
                                    <h2>{i18n.t('partner-title-description-label')}</h2>
                                </div>
                                {/* <ul>
                                    <li><Link data-filter="*" to="/" className="current" onClick={allElement}>All</Link></li>
                                    <li><Link data-filter=".Web-Design" to="/" onClick={webDesign}>Family Matters</Link></li>
                                    <li><Link data-filter=".Print-Design" to="/" onClick={printDesign}>Real Estate</Link></li>
                                    <li><Link data-filter=".Web-Application" to="/" onClick={webApp}>Business</Link></li>               
                                    <li><Link data-filter=".Photography" to="/" onClick={Photography}>Criminal</Link></li> 
                                </ul> */}
                            </div>
                            <div className="gallery-container gallery-fancybox masonry-gallery payra-masonary">
                                <div className={this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single div-content">
                                                <img src={Gallery_1} className="img img-responsive" alt="" />
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">Corporate</p>
                                                <h3>General Practice</h3>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className={this.state.onWebDesign ? "grid active" : "grid" 
                                    && this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_2} className="img img-responsive" alt="" />
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">General</p>
                                                <h3>Personal Issue</h3>
                                            </div>
                                        </div> */}
                                    </div>  
                                </div>
                                <div className={this.state.onWebDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                             <img src={Gallery_3} alt="" className="img img-responsive"/>
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">Business</p>
                                                <h3>Business Accounting</h3>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className={this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                        <div className="studies-item">
                                            <div className="studies-single">
                                                 <img src={Gallery_4} alt="" className="img img-responsive"/>
                                            </div>
                                            {/* <div className="overlay-text">
                                                <div className="text-inner">
                                                    <p className="sub">Family Issue</p>
                                                    <h3>Business Accounting</h3>
                                                </div>
                                            </div> */}
                                        </div>
                                </div>
                                <div className={this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_5} alt="" className="img img-responsive"/>
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">Corporate</p>
                                                <h3>General Practice</h3>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className={this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_6} alt="" className="img img-responsive"/>
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">Criminal</p>
                                                <h3>General Practice</h3>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className={this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_7} alt="" className="img img-responsive"/>
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">Criminal</p>
                                                <h3>General Practice</h3>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className={this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_8} alt="" className="img img-responsive"/>
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">Criminal</p>
                                                <h3>General Practice</h3>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className={this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_9} alt="" className="img img-responsive"/>
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">Criminal</p>
                                                <h3>General Practice</h3>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className={this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_10} alt="" className="img img-responsive"/>
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">Criminal</p>
                                                <h3>General Practice</h3>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className={this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_11} alt="" className="img img-responsive"/>

                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">Criminal</p>
                                                <h3>General Practice</h3>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className={this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_12} alt="" className="img img-responsive"/>
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">Criminal</p>
                                                <h3>General Practice</h3>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className={this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_13} alt="" className="img img-responsive"/>
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">Criminal</p>
                                                <h3>General Practice</h3>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className={this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_14} alt="" className="img img-responsive"/>
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">Criminal</p>
                                                <h3>General Practice</h3>
                                            </div>
                                        </div> */}
                                    </div>

                                </div>
                                <div className={this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_15} alt="" className="img img-responsive"/>
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">Criminal</p>
                                                <h3>General Practice</h3>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className={this.state.onWebDesign ? "grid active" : "grid" 
                                    && this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_16} className="img img-responsive" alt="" />
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">General</p>
                                                <h3>Personal Issue</h3>
                                            </div>
                                        </div> */}
                                    </div>  
                                </div>
                                <div className={this.state.onWebDesign ? "grid active" : "grid" 
                                    && this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_17} className="img img-responsive" alt="" />
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">General</p>
                                                <h3>Personal Issue</h3>
                                            </div>
                                        </div> */}
                                    </div>  
                                </div>
                                <div className={this.state.onWebDesign ? "grid active" : "grid" 
                                    && this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_18} className="img img-responsive" alt="" />
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">General</p>
                                                <h3>Personal Issue</h3>
                                            </div>
                                        </div> */}
                                    </div>  
                                </div>
                                <div className={this.state.onWebDesign ? "grid active" : "grid" 
                                    && this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_19} className="img img-responsive" alt="" />
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">General</p>
                                                <h3>Personal Issue</h3>
                                            </div>
                                        </div> */}
                                    </div>  
                                </div>
                                <div className={this.state.onWebDesign ? "grid active" : "grid" 
                                    && this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_20} className="img img-responsive" alt="" />
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">General</p>
                                                <h3>Personal Issue</h3>
                                            </div>
                                        </div> */}
                                    </div>  
                                </div>
                                <div className={this.state.onWebDesign ? "grid active" : "grid" 
                                    && this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_21} className="img img-responsive" alt="" />
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">General</p>
                                                <h3>Personal Issue</h3>
                                            </div>
                                        </div> */}
                                    </div>  
                                </div>
                                <div className={this.state.onWebDesign ? "grid active" : "grid" 
                                    && this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_22} className="img img-responsive" alt="" />
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">General</p>
                                                <h3>Personal Issue</h3>
                                            </div>
                                        </div> */}
                                    </div>  
                                </div>
                                <div className={this.state.onWebDesign ? "grid active" : "grid" 
                                    && this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_23} className="img img-responsive" alt="" />
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">General</p>
                                                <h3>Personal Issue</h3>
                                            </div>
                                        </div> */}
                                    </div>  
                                </div>
                                <div className={this.state.onWebDesign ? "grid active" : "grid" 
                                    && this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_24} className="img img-responsive" alt="" />
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">General</p>
                                                <h3>Personal Issue</h3>
                                            </div>
                                        </div> */}
                                    </div>  
                                </div>
                                <div className={this.state.onWebDesign ? "grid active" : "grid" 
                                    && this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_25} className="img img-responsive" alt="" />
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">General</p>
                                                <h3>Personal Issue</h3>
                                            </div>
                                        </div> */}
                                    </div>  
                                </div>
                                <div className={this.state.onWebDesign ? "grid active" : "grid" 
                                    && this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_26} className="img img-responsive" alt="" />
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">General</p>
                                                <h3>Personal Issue</h3>
                                            </div>
                                        </div> */}
                                    </div>  
                                </div>
                                <div className={this.state.onWebDesign ? "grid active" : "grid" 
                                    && this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_27} className="img img-responsive" alt="" />
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">General</p>
                                                <h3>Personal Issue</h3>
                                            </div>
                                        </div> */}
                                    </div>  
                                </div>
                                <div className={this.state.onWebDesign ? "grid active" : "grid" 
                                    && this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_28} className="img img-responsive" alt="" />
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">General</p>
                                                <h3>Personal Issue</h3>
                                            </div>
                                        </div> */}
                                    </div>  
                                </div>
                                <div className={this.state.onWebDesign ? "grid active" : "grid" 
                                    && this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_29} className="img img-responsive" alt="" />
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">General</p>
                                                <h3>Personal Issue</h3>
                                            </div>
                                        </div> */}
                                    </div>  
                                </div>
                                <div className={this.state.onWebDesign ? "grid active" : "grid" 
                                    && this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_30} className="img img-responsive" alt="" />
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">General</p>
                                                <h3>Personal Issue</h3>
                                            </div>
                                        </div> */}
                                    </div>  
                                </div>
                                <div className={this.state.onWebDesign ? "grid active" : "grid" 
                                    && this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_31} className="img img-responsive" alt="" />
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">General</p>
                                                <h3>Personal Issue</h3>
                                            </div>
                                        </div> */}
                                    </div>  
                                </div>
                                <div className={this.state.onWebDesign ? "grid active" : "grid" 
                                    && this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_32} className="img img-responsive" alt="" />
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">General</p>
                                                <h3>Personal Issue</h3>
                                            </div>
                                        </div> */}
                                    </div>  
                                </div>
                                <div className={this.state.onWebDesign ? "grid active" : "grid" 
                                    && this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.onWebApp ? "grid active" : "grid"
                                    && this.state.onPhotography ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <div className="studies-item">
                                        <div className="studies-single">
                                                <img src={Gallery_33} className="img img-responsive" alt="" />
                                        </div>
                                        {/* <div className="overlay-text">
                                            <div className="text-inner">
                                                <p className="sub">General</p>
                                                <h3>Personal Issue</h3>
                                            </div>
                                        </div> */}
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Porfolio;