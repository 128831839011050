import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/breadcumb'

import Features from '../../components/features'
import AboutSection from '../../components/about'
import PracticeArea from '../../components/PracticeArea'
import Testimonial from '../../components/testimonial'
import TeamSection from '../../components/Team'
import PartnersSection from '../../components/case'

import CounterSection from '../../components/CounterSection'
import BlogSection from '../../components/BlogSection'
import FooterSection from '../../components/Footer'
import i18next from 'i18next';
import i18n from './../../i18n';

const AboutPage = () => {
    return(
       <div className="about-layout">
           <Navbar/>
           <Breadcumb bdtitle={i18n.t('about-label')} bdsub={i18n.t('about-word-label')}/>
           {/* <Features/> */}
           <AboutSection/>
           <Testimonial/>
           <PracticeArea/>
           <TeamSection/>
           <PartnersSection/>
           {/* <CounterSection/> */}
           {/* <BlogSection/> */}
           <FooterSection/>
       </div>
    )
}

export default AboutPage;