import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/breadcumb'

import Contactpage from '../../components/Contactpage'
import FooterSection from '../../components/Footer'
import i18next from 'i18next';
import i18n from './../../i18n';

const ContactPage = () => {
    return(
       <div>
           <Navbar/>
           <Breadcumb bdtitle={i18n.t('contact-us-label')} bdsub={i18n.t('contact-word-label')}/>
           <Contactpage/>
           <FooterSection/>
       </div>
    )
}

export default ContactPage;