import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/breadcumb'

import Porfolio from '../../components/case'
import FooterSection from '../../components/Footer'
import i18next from 'i18next';
import i18n from './../../i18n';

const CaseStadies = () => {
    return(
       <div>
           <Navbar/>
           <Breadcumb bdtitle={i18n.t('partner-label')} bdsub={i18n.t('partner-recent-label')}/>
           <Porfolio/>
           <FooterSection/>
       </div>
    )
}

export default CaseStadies;