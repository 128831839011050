import React, { useState } from 'react';
import Slider from "react-slick";

import VideoModal from '../ModalVideo'
import {Link} from 'react-router-dom'
import abimg1 from '../../images/logo/thumbnail_logo_orange.png'
import abimg2 from '../../images/about/img-1.png'
import Parser from 'html-react-parser';
import Navbar from './../NavbarCommunication'
import Breadcumb from './../breadcumbCommunication'

import FooterSection from './../FooterCommunication'
import './style.css'
import i18next from 'i18next';
import i18n from './../../i18n';
import { useTranslation } from 'react-i18next';

const CommunicationSection = () => {
    const { i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState('en');
   
    const changeLanguage = (event) => {
      setSelectedLang(event.target.value);
      i18n.changeLanguage(event.target.value);
      localStorage.setItem('lang',event.target.value);
    }
    const settings = {
        dots: true,
        infinite: true,
        speed: 1200,
        slidesToShow: 2,
        arrows: false,
        margin:50,
        autoplay:true,
        slidesToScroll: 1,
        centerPadding: 30,
        focusOnSelect: false,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };
    
    return(
        <div>
            <Navbar/>
           <Breadcumb bdtitle={i18n.t('communication-label')} bdsub={i18n.t('communication-label')}/>
            <div id="communication-section" className="about-style-2">
                <div className="container">
                <div className="col-12">
                        <div className="section-title-communication-1 text-center">
                            <h2>{i18n.t('communication-label')}</h2>
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className="col-lg-6 col-sm-12 col-m">
                            <div className="about-img">
                                <img src={abimg1} alt=""/>
                            </div>
                        </div> */}
                        <div className="col-lg-12 col-12" >
                            <div className="about-content">
                                <div className="section-title-communication">
                                    <h2 >{i18n.t('about-label')}</h2>
                                </div>
                                <p className="justify-text">{Parser(i18n.t('communication-part1-label'))}</p>
                                <p className="justify-text">{Parser(i18n.t('communication-part2-label'))}</p>
                                <p>
                                    <h3 >{i18n.t('communication-expertise-label')}</h3>
                                    <div className="communication-list">
                                        <div className="communication-list-item">
                                            <ul>
                                                <li ><i className="fa fa-check-square-o"></i>{i18n.t('communication-expertise-element1-label')}</li>
                                                <li ><i className="fa fa-check-square-o"></i>{i18n.t('communication-expertise-element2-label')}</li>
                                                <li ><i className="fa fa-check-square-o"></i>{i18n.t('communication-expertise-element3-label')}</li>
                                            {/* </ul>
                                        </div>
                                        <div className="communication-list-item">
                                            <ul> */}
                                                <li ><i className="fa fa-check-square-o"></i>{i18n.t('communication-expertise-element4-label')}</li>
                                                <li ><i className="fa fa-check-square-o"></i>{i18n.t('communication-expertise-element5-label')}</li>
                                                <li ><i className="fa fa-check-square-o"></i>{i18n.t('communication-expertise-element6-label')}</li>
                                            {/* </ul>
                                        </div>
                                        <div className="communication-list-item">
                                            <ul> */}
                                                <li ><i className="fa fa-check-square-o"></i>{i18n.t('communication-expertise-element7-label')}</li>
                                                <li ><i className="fa fa-check-square-o"></i>{i18n.t('communication-expertise-element8-label')}</li>
                                                <li ><i className="fa fa-check-square-o"></i>{i18n.t('communication-expertise-element9-label')}</li>
                                            {/* </ul>
                                        </div>
                                        <div className="communication-list-item">
                                            <ul> */}
                                                <li ><i className="fa fa-check-square-o"></i>{i18n.t('communication-expertise-element10-label')}</li>
                                                <li ><i className="fa fa-check-square-o"></i>{i18n.t('communication-expertise-element11-label')}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <br/>
                                    <h3 >{i18n.t('communication-strategy-label')}</h3>
                                    <h5>{i18n.t('communication-strategy-description-label')}</h5>
                                    <p className="justify-text">
                                        {Parser(i18n.t('communication-strategy-detail-label'))}<br/>
                                    </p>
                                    <h3 >{Parser(i18n.t('communication-strategy-question-label'))}</h3>
                                    <ul className="communication-list-item">
                                        <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-strategy-list1-label')}</li>
                                        <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-strategy-list2-label')}</li>
                                        <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-strategy-list3-label')}</li>
                                        <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-strategy-list4-label')}</li>
                                        <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-strategy-list5-label')}</li>
                                        <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-strategy-list6-label')}</li>
                                        <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-strategy-list7-label')}</li>
                                    </ul>
                                </p> 
                                <div className="section-title-communication">
                                    <h2 >{i18n.t('communication-steps-label')}</h2>
                                </div>
                                <h3 className="paragraph-communication">{Parser(i18n.t('communication-steps-strategy-title-label'))}</h3>
                                <p className="justify-text">{i18n.t('communication-steps-strategy-description-label')}</p>
                                <h3 className="paragraph-communication">{Parser(i18n.t('communication-creative-strategy-title-label'))}</h3>
                                <p className="justify-text">{i18n.t('communication-creative-strategy-description-label')}</p>
                                <h3 className="paragraph-communication">{Parser(i18n.t('communication-operation-strategy-title-label'))}</h3>
                                <p className="justify-text">{i18n.t('communication-operation-strategy-description-label')}</p>
                                <h3 className="paragraph-communication">{i18n.t('communication-pilot-label')}</h3>
                                <h5>{i18n.t('communication-pilot-description-label')}</h5>
                                <p className="justify-text">
                                    {Parser(i18n.t('communication-pilot-detail-label'))}<br/>
                                </p>
                                <p>
                                    <h3 className="paragraph-communication">{Parser(i18n.t('communication-pilot-label'))}?</h3>
                                    <ul className="communication-list-item">
                                        <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-pilot-list1-label')}</li>
                                        <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-pilot-list2-label')}</li>
                                        <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-pilot-list3-label')}</li>
                                        <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-pilot-list4-label')}</li>
                                        <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-pilot-list5-label')}</li>
                                        <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-pilot-list6-label')}</li>
                                    </ul>
                                </p>
                                <p className="paragraph-communication">
                                    <div className="testimonial-active-communication">
                                        <Slider {...settings}>
                                            <div className="my-testimonial-communication particular-div-communication">
                                                <div className="inner-content">
                                                        <div className="component-ticket">
                                                            <div className="box-list-item">
                                                                <div className="rightTicket">1</div>
                                                                <h5 className="title-item">{i18n.t('communication-media-title-label')}</h5>
                                                                <p className="description-item">{Parser(i18n.t('communication-media-description-label'))}</p>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                            <div className="my-testimonial-communication particular-div-communication">
                                                <div className="inner-content">
                                                    <div className="component-ticket">
                                                        <div className="box-list-item">
                                                            <div className="rightTicket">2</div>
                                                            <h5 className="title-item">{i18n.t('communication-budget-title-label')}</h5>
                                                            <p className="description-item">{Parser(i18n.t('communication-budget-description-label'))}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="my-testimonial-communication particular-div-communication">
                                                <div className="inner-content">
                                                    <div className="component-ticket">
                                                        <div className="box-list-item">
                                                            <div className="rightTicket">3</div>
                                                            <h5 className="title-item">{i18n.t('communication-pilot-comm-title-label')}</h5>
                                                            <p className="description-item">{Parser(i18n.t('communication-pilot-comm-description-label'))}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </p>
                                <div>
                                    <div className="section-title-communication paragraph-communication-50">
                                        <h2 >{i18n.t('communication-digital-title-label')}</h2>
                                        <h4>{i18n.t('communication-web-agency-label')}</h4>
                                        <h5>{i18n.t('communication-web-agency-title-label')}</h5>
                                        <p className="justify-text">
                                            {Parser(i18n.t('communication-web-agency-description-label'))}<br/>
                                        </p>
                                        <p>
                                            <h3 className="paragraph-communication">{Parser(i18n.t('communication-web-competence-title-label'))}</h3>
                                            <ul className="communication-list-item">
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-competence-list1-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-competence-list2-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-competence-list3-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-competence-list4-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-competence-list5-label')}</li>
                                            </ul>
                                        </p>
                                        <p className="justify-text">{i18n.t('communication-web-supplem-text-label')}</p>
                                        <p>
                                            <h3 className="paragraph-communication">{Parser(i18n.t('communication-web-design-title-label'))}</h3>
                                            <ul className="communication-list-item">
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-design-list1-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-design-list2-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-design-list3-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-design-list4-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-design-list5-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-design-list6-label')}</li>
                                            </ul>
                                        </p>
                                        <p>
                                            <h3 className="paragraph-communication">{Parser(i18n.t('communication-web-developpment-title-label'))}</h3>
                                            <ul className="communication-list-item">
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-developpment-list1-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-developpment-list2-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-developpment-list3-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-developpment-list4-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-developpment-list5-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-developpment-list6-label')}</li>
                                            </ul>
                                        </p>
                                        <p>
                                            <h3 className="paragraph-communication">{Parser(i18n.t('communication-web-formation-title-label'))}</h3>
                                            <ul className="communication-list-item">
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-formation-list1-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-formation-list2-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-formation-list3-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-formation-list4-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-formation-list5-label')}</li>
                                            </ul>
                                        </p>
                                        <p className="paragraph-communication">
                                            <h4>{i18n.t('communication-web-reference-label')}</h4>
                                            <h5>{i18n.t('communication-web-reference-title-label')}</h5>
                                            <p className="justify-text">
                                                {Parser(i18n.t('communication-web-reference-description-label'))}<br/>
                                            </p>
                                        </p>
                                        <p>
                                            <h3 className="paragraph-communication">{Parser(i18n.t('communication-web-benchmark-title-label'))}</h3>
                                            <ul className="communication-list-item">
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-benchmark-list1-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-benchmark-list2-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-benchmark-list3-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-benchmark-list4-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-web-benchmark-list5-label')}</li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div className="section-title-communication">
                                        <h2 >{i18n.t('communication-savoir-faire-title-label')}</h2>
                                        <p className="paragraph-communication">
                                            <h5 className="key-style-paragraph">{i18n.t('communication-savoir-faire-key-title-label')}</h5>
                                            <p className="justify-text">
                                                {Parser(i18n.t('communication-savoir-faire-key-description-1-label'))}<br/>
                                            </p>
                                            <h6>{i18n.t('communication-methodology-label')}</h6>
                                            <p>{i18n.t('comunication-key-method-description-label')}</p>
                                            <p>
                                            <ul className="communication-list-item">
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('comunication-key-method-item-1-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('comunication-key-method-item-2-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('comunication-key-method-item-3-label')}</li>
                                            </ul>
                                            </p>
                                        </p>
                                        <p className="paragraph-communication">
                                            <h5 className="key-style-paragraph">{i18n.t('communication-savoir-faire-market-title-label')}</h5>
                                            <p className="justify-text">
                                                {Parser(i18n.t('communication-savoir-faire-market-description-1-label'))}<br/>
                                            </p>
                                            <h6>{i18n.t('communication-methodology-label')}</h6>
                                            <p>{i18n.t('comunication-market-method-description-label')}</p>
                                            <p>
                                            <ul className="communication-list-item">
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('comunication-market-method-item-1-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('comunication-market-method-item-2-label')}</li>
                                            </ul>
                                            </p>
                                        </p>
                                        <p className="paragraph-communication">
                                            <h5 className="key-style-paragraph">{i18n.t('communication-savoir-faire-affiliation-title-label')}</h5>
                                            <p className="justify-text">
                                                {Parser(i18n.t('communication-savoir-faire-affiliation-description-1-label'))}<br/>
                                            </p>
                                            <h6>{i18n.t('communication-methodology-label')}</h6>
                                            <p>{i18n.t('comunication-affiliation-method-description-label')}</p>
                                            <p>
                                            <ul className="communication-list-item">
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('comunication-affiliation-method-item-1-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('comunication-affiliation-method-item-2-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('comunication-affiliation-method-item-3-label')}</li>
                                            </ul>
                                            </p>
                                        </p>
                                        <div className="section-title-communication paragraph-communication-50">
                                            <h3>{i18n.t('communication-visit-title-label')}</h3>
                                            <h6>{i18n.t('communication-visit-description-label')}</h6>
                                            <p className="justify-text">
                                                {Parser(i18n.t('communication-visit-paragraph-label'))}<br/>
                                            </p>
                                            <p className="paragraph-communication">
                                                <h4 className="paragraph-communication">{Parser(i18n.t('communication-domain-title-label'))}</h4>
                                                <ul className="communication-list-item">
                                                    <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-domain-item-1-label')}</li>
                                                    <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-domain-item-2-label')}</li>
                                                    <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-domain-item-3-label')}</li>
                                                    <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-domain-item-4-label')}</li>
                                                    <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-domain-item-5-label')}</li>
                                                    <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-domain-item-6-label')}</li>
                                                    <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-domain-item-7-label')}</li>
                                                    <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-domain-item-8-label')}</li>
                                                </ul>
                                            </p>
                                            <p className="paragraph-communication">
                                                <h3>{i18n.t('communication-savoir-faire-title-label')}</h3>
                                                <div className="testimonial-active-communication">
                                                    <Slider {...settings}>
                                                        <div className="my-testimonial-communication particular-div-communication">
                                                            <div className="inner-content-do">
                                                                    <div className="component-ticket">
                                                                        <div className="box-list-item-do">
                                                                            <h5 className="title-item-do">{i18n.t('communication-google-360-title-label')}</h5>
                                                                            <p className="description-item-do">{Parser(i18n.t('communication-google-360-description-label'))}</p>
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                        </div>
                                                        <div className="my-testimonial-communication particular-div-communication">
                                                            <div className="inner-content-do">
                                                                <div className="component-ticket">
                                                                    <div className="box-list-item-do">
                                                                        <h5 className="title-item-do">{i18n.t('communication-virtual-visit-clasic-title-label')}</h5>
                                                                        <p className="description-item-do">{Parser(i18n.t('communication-virtual-visit-clasic-description-label'))}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="my-testimonial-communication particular-div-communication">
                                                            <div className="inner-content-do">
                                                                <div className="component-ticket">
                                                                    <div className="box-list-item-do">
                                                                        <h5 className="title-item-do">{i18n.t('communication-virtual-visit-interactive-title-label')}</h5>
                                                                        <p className="description-item-do">{Parser(i18n.t('communication-virtual-visit-interactive-description-label'))}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Slider>
                                                </div>
                                            </p>
                                        </div>
                                        
                                    </div>
                                    <div className="section-title-communication paragraph-communication-50">
                                        <h2 >{i18n.t('communication-creation-title-label')}</h2>
                                        <h4>{i18n.t('communication-creation-subtitle-1-label')}</h4>
                                        <p className="justify-text">
                                            {Parser(i18n.t('communication-creation-description'))}<br/>
                                        </p>
                                        <h4>{i18n.t('communication-creation-subtitle-2-label')}</h4>
                                        <ul className="communication-list-item">
                                            <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-creation-subtitle-2-item-1-label')}</li>
                                            <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-creation-subtitle-2-item-2-label')}</li>
                                            <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-creation-subtitle-2-item-3-label')}</li>
                                            <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-creation-subtitle-2-item-4-label')}</li>
                                            <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-creation-subtitle-2-item-5-label')}</li>
                                        </ul>
                                        <p className="justify-text paragraph-communication">
                                            {Parser(i18n.t('communication-creation-subtitle-2-description-label'))}<br/>
                                        </p>
                                    </div>
                                    <div className="section-title-communication ">
                                        <h2 >{i18n.t('communication-naming-title-label')}</h2>
                                        <h4>{i18n.t('communication-naming-subtitle-1-label')}</h4>
                                        <p className="justify-text">
                                            {Parser(i18n.t('communication-naming-description'))}<br/>
                                        </p>
                                        <h4>{i18n.t('communication-naming-subtitle-2-label')}</h4>
                                        <ul className="communication-list-item">
                                            <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-subtitle-2-item-1-label')}</li>
                                            <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-subtitle-2-item-2-label')}</li>
                                            <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-subtitle-2-item-3-label')}</li>
                                            <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-subtitle-2-item-4-label')}</li>
                                            <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-subtitle-2-item-5-label')}</li>
                                        </ul>
                                        <p className="justify-text paragraph-communication">
                                            <h3 >{i18n.t('communication-naming-subtitle-3-label')}</h3>
                                            <h4>{i18n.t('communication-naming-subtitle-3-description-label')}</h4>
                                            <p className="justify-text">
                                                {Parser(i18n.t('communication-naming-subtitle-3-paragraph-label'))}<br/>
                                            </p>
                                            <h4>{i18n.t('communication-naming-subtitle-4-label')}</h4>
                                            <ul className="communication-list-item">
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-subtitle-4-item-1-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-subtitle-4-item-2-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-subtitle-4-item-3-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-subtitle-4-item-4-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-subtitle-4-item-5-label')}</li>
                                            </ul>
                                        </p>
                                        <p className="paragraph-communication">
                                            <h5 className="key-style-paragraph">{i18n.t('communication-naming-print-title-label')}</h5>
                                            <p className="justify-text">
                                                {Parser(i18n.t('communication-naming-print-description-1-label'))}<br/>
                                            </p>
                                            <h5 className="key-style-paragraph">{i18n.t('communication-naming-digital-title-label')}</h5>
                                            <p className="justify-text">
                                                {Parser(i18n.t('communication-naming-digital-description-1-label'))}<br/>
                                            </p>
                                            <h5 className="key-style-paragraph">{i18n.t('communication-naming-signal-title-label')}</h5>
                                            <p className="justify-text">
                                                {Parser(i18n.t('communication-naming-signal-description-1-label'))}<br/>
                                            </p>
                                
                                        </p>
                                        <p className="justify-text paragraph-communication">
                                            <h3 >{i18n.t('communication-naming-subtitle-5-label')}</h3>
                                            <h4>{i18n.t('communication-naming-subtitle-5-description-label')}</h4>
                                            <p className="justify-text">
                                                {Parser(i18n.t('communication-naming-subtitle-5-paragraph-label'))}<br/>
                                            </p>
                                            <h4>{i18n.t('communication-naming-subtitle-6-label')}</h4>
                                            <ul className="communication-list-item">
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-subtitle-6-item-1-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-subtitle-6-item-2-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-subtitle-6-item-3-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-subtitle-6-item-4-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-subtitle-6-item-5-label')}</li>
                                            </ul>
                                            <p className="justify-text paragraph-communication">
                                                {Parser(i18n.t('communication-naming-paragraph-supplem-label'))}<br/>
                                            </p>
                                        </p>
                                        <p>
                                            <h3 className="paragraph-communication">{Parser(i18n.t('communication-naming-video-title-label'))}</h3>
                                            <ul className="communication-list-item">
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-video-list1-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-video-list2-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-video-list3-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-video-list4-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-video-list5-label')}</li>
                                            </ul>
                                        </p>
                                        <p className="justify-text">{i18n.t('communication-web-supplem-text-label')}</p>
                                        <p>
                                            <h3 className="paragraph-communication">{Parser(i18n.t('communication-naming-animation-title-label'))}</h3>
                                            <ul className="communication-list-item">
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-animation-list1-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-animation-list2-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-animation-list3-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-animation-list4-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-animation-list5-label')}</li>
                                            </ul>
                                        </p>
                                        <p>
                                            <h3 className="paragraph-communication">{Parser(i18n.t('communication-naming-web-tv-title-label'))}</h3>
                                            <ul className="communication-list-item">
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-web-tv-list1-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-web-tv-list2-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-web-tv-list3-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-web-tv-list4-label')}</li>
                                                <li style={{marginLeft:"10px"}}><i className="fa fa-check-square-o"></i>{i18n.t('communication-naming-web-tv-list5-label')}</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        {/* <div className="col-lg-12 col-12">
                            <p>
                                {Parser(i18n.t('about-mdscresoft-three-label'))}
                            </p>
                        </div> */}
                        {/* <div className="btns">
                            <div className="btn-style"><Link to="/">{i18n.t('more-about-label')}</Link></div>
                        </div> */}
                        <div className="signature">
                            <img src={abimg2} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <FooterSection/>
        </div>

     )
        
}

export default CommunicationSection;