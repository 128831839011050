import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/breadcumb'

import Features from '../../components/features'
import PracticeArea from '../../components/PracticeArea'
// import PricingSection from '../../components/Pricing'
import Testimonial from '../../components/testimonial'
import FooterSection from '../../components/Footer'
import TelecomSection from '../../main-component/PracticeDetails/TelecomSection'
import i18next from 'i18next';
import i18n from './../../i18n';

const PracticePage = () => {
    return(
       <div className="practicepage">
           {/* <Navbar/> */}
           {/* <Breadcumb bdtitle={i18n.t('practice-label')} bdsub={i18n.t('practice-label')}/> */}
           {/* <Features/> */}
           <TelecomSection/>
           {/* <PracticeArea/> */}
           {/* <PricingSection/> */}
           {/* <Testimonial/> */}
           {/* <FooterSection/> */}
       </div>
    )
}

export default PracticePage;