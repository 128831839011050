import i18next from 'i18next';
import React from 'react';
import ContactForm from '../CommentForm2'
import './style.css'
import i18n from './../../i18n';
import Parser from 'html-react-parser';

const ContactSection = () => {

    return(
        <div className="contact-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="contact-text">
                            <h2>{i18n.t('contact-title-label')}</h2>
                            <p>{i18n.t('contact-title-description-label')}</p>
                            <div className="contact-sub">
                                <div className="contact-icon">
                                    <i className="fa fa-map-marker" aria-hidden="true" style={{color:"#15729f"}}></i>
                                </div>
                                <div className="contact-c">
                                    <h4>{i18n.t('contact-location-label')}</h4>
                                    <span>{Parser(i18n.t('mdscresoft-address'))}</span>
                                </div>
                            </div>
                            <div className="contact-sub">
                                <div className="contact-icon">
                                    <i className="fa fa-phone" aria-hidden="true" style={{color:"#15729f"}}></i>
                                </div>
                                <div className="contact-c">
                                    <h4>{i18n.t('contact-phone-label')}</h4>
                                    <span>+222 45 25 93 40</span><br/>
                                    <span>+222 49 54 19 82</span>
                                </div>
                            </div>
                            <div className="contact-sub">
                                <div className="contact-icon">
                                    <i className="fa fa-envelope-o" aria-hidden="true" style={{color:"#15729f"}}></i>
                                </div>
                                <div className="contact-c">
                                    <h4>{i18n.t('contact-email-label')}</h4>
                                    <span>contact@mdscresoft-group.com</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-7 col-md-12 col-sm-12">
                        <div className="contact-content">
                            <div className="contact-form">
                                    <ContactForm/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default ContactSection;
