import i18next from 'i18next';
import React from 'react';
import ContactForm2 from '../CommentForm2'
import Parser from 'html-react-parser';
import i18n from './../../i18n';
import './style.css'

const Contactpage = () => {

    return(
        <div id="contact-section" className="contact-page-area section-padding">
            <div className="container">
                <div className="col-l2">
                    <div className="section-title-1  text-center">
                        <span>{i18n.t('contact-title-two-label')}</span>
                        <h2>{i18n.t('contact-section-label')}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-5 col-md-12">
                        <div className="contact-page-item">
                            {/* <h2>{i18n.t('contact-title-two-label')}</h2> */}
                            {/* <p>{i18n.t('contact-title-description-label')}</p> */}
                            <div className="adress">
                                <h3>MDSCRESOFT</h3>
                                <span>{Parser(i18n.t('mdscresoft-address'))}</span>
                                <ul className="d-flex account_login-area" style={{textAlign:"left"}}>
                                    <li><p><i className="fa fa-phone"></i>+222 45 25 93 40</p></li>
                                    <li><p><i className="fa fa-phone"></i>+222 49 54 19 82</p></li>
                                </ul>
                            </div>
                            <div className="adress">
                                <h3>MDSCRESOFT-R&D ITESLAB</h3>
                                <span>{Parser(i18n.t('mdscresoft-subaddress-one'))}</span>
                                <ul className="d-flex account_login-area" style={{textAlign:"left"}}>
                                    <li><p><i className="fa fa-phone"></i>+216 71 87 00 31</p></li>
                                    {/* <li><p><i className="fa fa-fax"></i>+216 71 856 759</p></li> */}
                                </ul>
                            </div>
                            <div className="adress">
                                <h3>MDSCRESOFT-Smart</h3>
                                <span>{Parser(i18n.t('mdscresoft-subaddress-two'))}</span>
                                <ul className="d-flex account_login-area" style={{textAlign:"left"}}>
                                    <li><p><i className="fa fa-phone"></i>+222 55 00 04 54</p></li>
                                </ul>
                            </div>
                            <div className="adress">
                                <h3>MDSCRESOFT-FreakAppB</h3>
                                <span>{Parser(i18n.t('mdscresoft-subaddress-three'))}</span>
                            </div>
                            <div className="adress">
                                <h3>MDSCRESOFT-Sénégal</h3>
                                <span>{Parser(i18n.t('mdscresoft-subaddress-four'))}</span>
                            </div>
                            
                            {/* <div className="adress">
                                <h3>{i18n.t('contact-address-label')}</h3>
                                <span>{Parser(i18n.t('mdscresoft-address'))}</span>
                            </div>
                            <div className="phone">
                                <h3>{i18n.t('contact-phone-label')}</h3>
                                <span>+222 45 25 93 40</span>
                                <span>+222 49 54 19 82</span>
                            </div>
                            <div className="email">
                                <h3>{i18n.t('contact-email-label')}</h3>
                                <span>mdscresoft-group@gmail.com</span>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12">
                        <div className="contact-area contact-area-2 contact-area-3">
                            <h2>{i18n.t('contact-description-two-label')}</h2>
                            <ContactForm2/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div id="map-section" className="col col-xs-12">
                        <div className="contact-map">
                            {/* <iframe title='db' src="https://www.google.com/maps/search/Avenue+du+G%C3%A9n%C3%A9ral+de+Gaulle+Bureau+TAWFIK+B%C3%A2timent+C3+Nouakchott-Mauritanie/@18.0671201,-16.0936505,11z/data=!3m1!4b1"></iframe> */}
                            {/* <iframe title='db' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d57763.58882182253!2d55.38442113562169!3d25.195692423227655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2z4Kam4KeB4Kas4Ka-4KaHIC0g4Kam4KeB4Kas4Ka-4KaHIOCmhuCmruCmv-CmsOCmvuCmpCAtIOCmuOCmguCmr-CngeCmleCnjeCmpCDgpobgprDgpqwg4KaG4Kau4Ka_4Kaw4Ka-4Kak!5e0!3m2!1sbn!2sbd!4v1540725271741"></iframe> */}
                            <iframe src="https://maps.google.com/maps?q=+18.091196826781527,-16.00805352544109&z=16&amp&output=embed"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default Contactpage;
